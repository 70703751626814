import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { RouteMap } from '../../route/Index'
import PrivateRoute from '../../components/privateRoute/Index'
import { Layout, Menu } from 'antd';
import './Layout.css';
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined
} from '@ant-design/icons';
import Tags from './Tags'

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const Layout_: React.FC<any> = () => {
	const history = useHistory()

	const [collapsed, setCollapsed] = useState(false)
	const loginOut = () => {
		if (localStorage.backUrl) window.location.href = localStorage.backUrl
		localStorage.clear()
	}
	return (
		<Layout>
			<Sider trigger={null} collapsible collapsed={collapsed}>
				<div className={`logo ${collapsed ? 'small' : ''}`}>LOGO</div>
				<Menu className="menu_box scrollbar" theme="dark" mode="inline" selectedKeys={[history.location.pathname.replace('/', '')]}>
					{
						RouteMap.map((item: any) => (
							!item.notMenu &&
							(
								item.children && item.children.length > 0 ?
									<SubMenu key={item.name} icon={item.icon} title={item.title}>
										{
											item.children.map((it: any) => (
												<Menu.Item key={it.name} onClick={() => history.push(it.path)}>
													{it.title}
												</Menu.Item>
											))
										}
									</SubMenu> :
									<Menu.Item key={item.name} icon={item.icon} onClick={() => history.push(item.path)}>
										{item.title}
									</Menu.Item>
							)
						))
					}
				</Menu>
			</Sider>
			<Layout className="site-layout">
				<Header className="bgf flex_just_betw flex_cen" style={{ padding: '0 15px' }}>
					<div className="flex_just_betw flex_cen">
						{
							collapsed ?
								<MenuUnfoldOutlined onClick={() => setCollapsed(!collapsed)} style={{ fontSize: 20 }} /> :
								<MenuFoldOutlined onClick={() => setCollapsed(!collapsed)} style={{ fontSize: 20 }} />
						}
						<div className="ml20"><p>首页</p></div>
					</div>
					<div className="loginOutBtn" onClick={() => loginOut()}>退出登录</div>
				</Header>
				<Tags></Tags>
				<Content
					className="bgf"
					style={{
						margin: '15px',
						padding: 10,
						minHeight: 280,
					}}
				>
					<PrivateRoute />
				</Content>
			</Layout>
		</Layout>
	)
}

export default Layout_