import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
/**获取token  start */
const getQueryVariable = (variable: any) => {//获取地址栏参数
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] === variable) { return pair[1]; }
	}
	return (false);
}
//token存储
let authorization = getQueryVariable('token')
if (authorization) {
	localStorage.setItem('token', authorization)
}

switch (process.env.NODE_ENV) {
	case 'testbuild':
		localStorage.backUrl = 'http://10.10.22.174:8080/#/Login'
		break;
	case 'prodbuild':
		localStorage.backUrl = 'https://login.cdyushun.com/#/Login'
		break;
	default: 
		localStorage.backUrl = 'http://10.10.22.174:8080/#/Login'
		break;
}

//获取单点登录跳转过来的路由参数
let backUrl = getQueryVariable('backUrl') //放回单点登录的地址url
if (backUrl) {
	localStorage.setItem('backUrl', backUrl)
}

let appId = getQueryVariable('appId') //系统id
if (appId) {
	localStorage.setItem('appId', appId)
}
ReactDOM.render(
	<ConfigProvider locale={zh_CN}>
		<App />
	</ConfigProvider>,
	document.getElementById('root')
);

